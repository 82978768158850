import scrollTo from '../../../../components/scrollTo/scrollTo'

export default class configureCell {
    constructor(props) {
        this.cell = document.createElement('td')
        this.props = props
        this.parent = props.parent
        this.formElem = document.createElement('form')
        this.addButtonElem = document.createElement('button')
        this.id = this.parent.id
        this.observer = props.observer
        this.selectedVariant = props.selectedVariant
    }

    submitForm(e) {
        e.preventDefault()
        e.stopPropagation()
        const elementToUpdate = document.querySelector('.npp__attributes-dropdowns')
        elementToUpdate.setAttribute('data-selected-variant', this.parent.id)
        scrollTo(elementToUpdate, -160)
    }

    render() {
        this.cell.className = 'v-td configure-cell'

        this.formElem.id = 'configure_form'
        this.formElem.method = 'POST'
        this.formElem.noValidate = true

        this.addButtonElem.classList.add('configure-button')
        this.addButtonElem.addEventListener('click', (e) => this.submitForm(e))
        this.addButtonElem.innerText = `Configure`
        this.formElem.appendChild(this.addButtonElem)
        this.cell.appendChild(this.formElem)

        return this.cell
    }
}
