import rowsFactory from '../rowsFactory/rowsFactory'
import cellsFactory from '../cellsFactory/cellsFactory'
import { maxColumnsDesktop, setColumnsClasses, changeColumnsOrder } from '../partials/columnsController'
import VARIANTS_TYPES from '../../config/variants-config'

export default class columnsHeader {
    constructor(props) {
        this.props = props
        this.isSwr = this.props.data.options.isSwr
        this.isCustomProductPageActive = this.props.data.options.isCustomProductPageActive
        this.isWebshopActive = this.props.data.options.isWebshopActive
        this.thead = document.createElement('thead')
    }

    render() {
        let columnsToRender = []
        this.thead.setAttribute('class', 'v-thead columns-header')
        const row = new rowsFactory(VARIANTS_TYPES.EMPTY).render()

        if ((this.props.type === VARIANTS_TYPES.SUBVARIANTS || this.isSwr) && !this.isCustomProductPageActive) {
            row.appendChild(new cellsFactory(VARIANTS_TYPES.HEADER, { exclude: true }).render())
        }

        this.props.data.columns.map((column) => {
            if (!column.key.startsWith('Measurement.')) {
                columnsToRender.push(new cellsFactory(VARIANTS_TYPES.HEADER, { label: column.label, key: column.key }).render())
            }
        })

        if (this.props.type === VARIANTS_TYPES.GENERIC_VARIANTS || this.props.type === VARIANTS_TYPES.GENERIC_VARIANTS_SWR) {
            if (this.props.data.options.isBlueprint) {
                columnsToRender.push(
                    new cellsFactory(VARIANTS_TYPES.HEADER, { label: `${window.globals.trans('filter.blueprint')}`, key: VARIANTS_TYPES.BLUEPRINT }).render(),
                )
            }
            if (this.props.data.options.isCadDownloadActive) {
                columnsToRender.push(
                    new cellsFactory(VARIANTS_TYPES.HEADER, { label: `${window.globals.trans('table.heading.CAD')}`, key: VARIANTS_TYPES.CAD }).render(),
                )
            }

            const newColumnsOrder = changeColumnsOrder(columnsToRender)
            newColumnsOrder.forEach((item) => {
                row.appendChild(item)
            })

            setColumnsClasses(row)

            if (this.props.type !== VARIANTS_TYPES.SUBVARIANTS && !this.isSwr && !this.isCustomProductPageActive) {
                const fastBuyCell = new cellsFactory(VARIANTS_TYPES.HEADER, { label: `${window.globals.trans('filter.fast_buy')}`, exclude: true }).render()
                row.appendChild(fastBuyCell)
            }

            if (this.isCustomProductPageActive) {
                const configureCell = new cellsFactory(VARIANTS_TYPES.HEADER, {
                    label: `${window.globals.trans('filter.configure-swr')}`,
                    exclude: true,
                }).render()
                row.appendChild(configureCell)
            }

            const moreCell = new cellsFactory(VARIANTS_TYPES.HEADER, { label: `${window.globals.trans('filter.more_details')}`, exclude: true }).render()
            if (!(row.childNodes.length > maxColumnsDesktop)) {
                moreCell.classList.add('hide-on-desktop')
            }
            row.appendChild(moreCell)
        } else if (this.props.type === VARIANTS_TYPES.SUBVARIANTS) {
            const newColumnsOrder = changeColumnsOrder(columnsToRender)
            newColumnsOrder.forEach((item) => {
                row.appendChild(item)
            })
            setColumnsClasses(row, 99, 4)
        }

        this.thead.appendChild(row)
        return this.thead
    }
}
